<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ref } from "vue";
import shine from "user/assets/lotties/Shine-BG.json";
import {
  Rarity,
  ReceiveInitialReward,
  Reward,
  RewardClassName,
} from "user/models/items";
import { useMarketStore } from "user/stores/marketplace";
import getBackgroundColor from "user/utils/getBackgroundColor";
import { useSessionStore } from "user/stores/session";
import { Currency } from "user/models/currency";
import lootboxSound from "user/assets/audio/lootbox/lootbox-open";
import receiveModalEnterSound from "user/assets/audio/modal/receive-enter";
import CloseIcon from "user/assets/close.svg";
import { toBlob } from "html-to-image";

const classToExcludeFromScreenshot = "excluded-from-screenshot";

const marketStore = useMarketStore();
const sessionStore = useSessionStore();

const open = defineModel<boolean>("open", { required: true });
const prize = defineModel<Reward>("prize", {
  required: true,
});

defineProps<{
  isMarket?: boolean;
  isMultiple?: boolean;
  isOnboard?: boolean;
  sellingPrice?: Currency;
  availableSpinVouchers?: number;
}>();

const emit = defineEmits<{
  goToHome: [];
  goToBackpack: [];
  goToMarket: [];
  goToSpinAndWin: [];
  continue: [];
  buySpin: [];
  close: [];
  isOpenAnonymousModal: [];
  spin: [];
}>();

// if bg depend from reward - need remove initial reward and pass default prize to bgColor
const initialReward: ReceiveInitialReward = {
  is_mystery_box_reward: false,
  rarity: Rarity.default,
  class_name: undefined,
  sellingPrice: undefined,
  is_jackpot_reward: false,
};

const isAnimated = ref(true); // wait till headlessui transition done

// jackpot
const hideTextJackpot = ref(false);
const jackpotAnimationActive = ref(false);
//mystery box
const hideTextMB = ref(false);
const mbAnimationActive = ref(false);
// hideText for hiding lootbox text while lootbox animation
const hideText = ref(false);
const lbAnimationActive = ref(false);
const prizeAnimationActive = ref(false);
// done for hiding reward text while reward animation
const done = ref(false);
const lootboxReward = ref<Reward>();

const dialogPanelRef = ref<(HTMLDivElement & { $el: HTMLElement }) | null>(
  null,
);
const rewardScreenFile = ref<Blob | null>(null);
const screenCaptureSuccess = ref(false);

async function onCopyImage() {
  if (!dialogPanelRef.value?.$el || !dialogPanelRef.value) return "";

  const dialogPanelElement = dialogPanelRef.value?.$el || dialogPanelRef.value;

  const filterButtons = (node: HTMLElement) => {
    return !node.classList?.contains(classToExcludeFromScreenshot);
  };

  try {
    const backgroundColorClass = getBackgroundColor(
      initialReward.rarity,
      "radial",
      initialReward.class_name,
      initialReward.is_mystery_box_reward,
    );

    dialogPanelElement.classList.add(backgroundColorClass, "w-full");

    const blob = await toBlob(dialogPanelElement, {
      filter: filterButtons,
    });

    rewardScreenFile.value = blob;

    if (blob) {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);

      const copyImageButtonElement =
        document.getElementById("copy-image-button");
      copyImageButtonElement &&
        copyImageButtonElement.classList.add("opacity-40");

      screenCaptureSuccess.value = true;

      setTimeout(() => {
        screenCaptureSuccess.value = false;
      }, 5000);

      console.info("Image copied!");
    }
  } catch (e) {
    console.error("Could not copy image", e);
  }
}
function startPrizeAnimation() {
  // get the asset, set to true, activate animation
  prizeAnimationActive.value = true;
  prize.value = lootboxReward.value as Reward;
}

function endMysteryBoxAnimation() {
  prize.value.is_mystery_box_reward = false;
  if (prize.value.is_jackpot_reward) {
    jackpotAnimationActive.value = true;
    return;
  }
  if (prize.value.class_name !== RewardClassName.Lootbox) {
    prizeAnimationActive.value = true;
  }
}

function setDone() {
  done.value = true;
  isAnonymousHandle();
}

function openMysteryBox() {
  hideTextMB.value = true;
  lootboxSound.play();
  setTimeout(() => {
    mbAnimationActive.value = true;
  }, 100);
}

async function openLootbox() {
  if (isAnimated.value) {
    return;
  }
  const resp = await marketStore.openLootbox(prize.value.id);
  lootboxReward.value = resp;
  handleLootbox();
  await sessionStore.tryFetchCurrentUser();
}

function isAnonymousHandle() {
  if (sessionStore.isAnonymous) {
    emit("isOpenAnonymousModal");
  }
}

function goToHome() {
  emit("goToHome");
}

function goToBackpack() {
  emit("goToBackpack");
}

function goToMarket() {
  emit("goToMarket");
}

function buySpin() {
  emit("buySpin");
}

function goToSpinAndWin() {
  emit("goToSpinAndWin");
}

function handleLootbox() {
  hideText.value = true;
  lootboxSound.play();
  setTimeout(() => (lbAnimationActive.value = true), 100);
}

function handleJackpotAnimationDone() {
  prize.value.is_jackpot_reward = false;
  prize.value = prize.value.jackpot_reward as Reward;
  hideTextJackpot.value = false;
  prizeAnimationActive.value = true;
}

function handleGetJackpot() {
  hideTextJackpot.value = true;
  jackpotAnimationActive.value = true;
}

function handelSpin() {
  emit("spin");
  open.value = false;
}

function handleClose() {
  open.value = false;
  emit("close");
}

const isAnyAnimation = computed(() => {
  return isAnimated.value || prizeAnimationActive.value
    ? !done.value
    : false || lbAnimationActive.value
      ? hideText.value
      : false;
});

watch(open, () => {
  if (open.value && prize.value) {
    receiveModalEnterSound.play();
  }
  if (
    open.value &&
    prize.value.class_name !== RewardClassName.Lootbox &&
    !prize.value.is_mystery_box_reward &&
    !prize.value.is_jackpot_reward
  ) {
    prizeAnimationActive.value = true;
  }
});

onMounted(() => {
  setTimeout(() => {
    isAnimated.value = false;
  }, 1000);

  if (prize.value && open.value) {
    receiveModalEnterSound.play();
  }

  initialReward.is_mystery_box_reward =
    prize.value?.is_mystery_box_reward || false;
  initialReward.rarity = prize.value.rarity;
  initialReward.class_name = prize.value.class_name;
  initialReward.sellingPrice = prize.value.price;
  initialReward.is_jackpot_reward = prize.value.is_jackpot_reward;

  if (
    open.value &&
    prize.value.class_name !== RewardClassName.Lootbox &&
    !prize.value?.is_mystery_box_reward &&
    !prize.value.is_jackpot_reward
  ) {
    prizeAnimationActive.value = true;
  }
});
</script>

<template>
  <TransitionRoot :show="open">
    <!-- <Dialog as="div" class="relative z-[70]" @close="handleClose"> with enabled @close="handleClose" any click on other component/element lead to closing Receive Modal-->
    <Dialog as="div" class="relative z-[70]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 z-30 w-full h-full"
          :class="[
            getBackgroundColor(
              initialReward.rarity,
              'radial',
              initialReward.class_name,
              initialReward.is_mystery_box_reward,
              initialReward.is_jackpot_reward,
            ),
          ]"
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          ref="dialogPanelRef"
          class="fixed py-10 left-0 right-0 top-0 z-50 m-auto flex h-full w-[90vw] flex-col items-center justify-between"
        >
          <TransitionChild
            as="template"
            enter="duration-700 transition-opacity transition-transform delay-300"
            enter-from="opacity-0 scale-0"
            enter-to="opacity-100 scale-100"
            leave="duration-700 ease-in transition-opacity"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-0"
          >
            <div
              class="fixed h-full w-full flex justify-center items-center -z-10"
            >
              <dotlottie-player
                :src="shine"
                :autoplay="true"
                :loop="true"
                class="scale-[250%]"
              />
            </div>
          </TransitionChild>
          <div
            v-if="!isAnyAnimation"
            @click="handleClose"
            :class="`${classToExcludeFromScreenshot} fixed top-4 right-4 bg-white rounded-full p-2 flex justify-center items-center z-20 cursor-pointer`"
          >
            <img
              :src="CloseIcon"
              alt="Close"
              class="size-[10rem] object-contain"
            />
          </div>
          <ReceiveJackpotModal
            v-if="prize.is_jackpot_reward"
            :prize="prize"
            :hide-text-jackpot="hideTextJackpot"
            :jackpot-animation-active="jackpotAnimationActive"
            :is-animated="isAnimated"
            @end-jackpot-animation="handleJackpotAnimationDone"
            @get-jackpot="handleGetJackpot"
          />
          <ReceiveMysteryBoxModal
            v-else-if="prize.is_mystery_box_reward"
            :prize="prize"
            :hide-text-m-b="hideTextMB"
            :mb-animation-active="mbAnimationActive"
            :is-animated="isAnimated"
            @open-mystery-box="openMysteryBox"
            @end-mystery-box-animation="endMysteryBoxAnimation"
          />
          <ReceiveRewardModal
            v-else-if="prize.class_name !== RewardClassName.Lootbox"
            :prize="prize"
            :prize-animation-active="prizeAnimationActive"
            :done="done"
            @go-to-backpack="goToBackpack"
            @go-to-home="goToHome"
            @go-to-market="goToMarket"
            @go-to-spin-and-win="goToSpinAndWin"
            @set-done="setDone"
            @continue="emit('continue')"
            @copy-image="onCopyImage"
            :is-market="isMarket"
            :is-multiple="isMultiple"
            :is-onboard="isOnboard"
            :selling-price="initialReward.sellingPrice"
            :reward-screen-file="rewardScreenFile"
            :class-to-exclude-from-screenshot="classToExcludeFromScreenshot"
            :copy-success="screenCaptureSuccess"
            :available-spin-vouchers="availableSpinVouchers"
            @buy-spin="buySpin"
            @spin-again="handleClose"
            @spin="handelSpin"
          />
          <ReceiveLootboxModal
            v-else-if="prize.class_name === RewardClassName.Lootbox"
            :prize="prize"
            :hide-text="hideText"
            :lb-animation-active="lbAnimationActive"
            :is-animated="isAnimated"
            :is-market="isMarket"
            @open-lootbox="openLootbox"
            @start-prize-animation="startPrizeAnimation"
            :selling-price="initialReward.sellingPrice"
          />
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
