import { Reward } from "user/models/items";
import { MissionClassName } from "admin/models/mission";
export { MissionClassName } from "admin/models/mission";

export const enum MissionFilter {
  Available = "available",
  Completed = "completed",
  Active = "active",
}

export interface MissionCategory {
  name: string;
  count: number;
  active: boolean;
}

export const enum MissionStatus {
  Pending = "pending",
  Completed = "completed",
}

export interface Mission {
  id: number;
  identifier: string;
  class_name?: MissionClassName;
  type?: MissionType;
  name: string;
  description: string;
  icon: string;
  mission_banner: string;
  game?: string;
  website?: string;
  expires_at?: string;
  accepted_at?: string;
  conditions?: Conditions | Conditions[];
  rewards: Reward[];
  availability: string;
  short_description?: string;
  isClaimable?: boolean;
  next_repeat_time?: string; // date for repeatable mission
  mission_expiry?: string; // date for repeatable mission

  //our types
  isAvailable?: boolean;
  isActive?: boolean;
  isCompleted?: boolean;
}

export enum MissionType {
  Linkout = "LinkoutEvent",
  ConnectAccount = "ConnectAccount",
  InventoryEvent = "InventoryEvent",
  RobloxUpdate = "RobloxUpdate",
  ReferralEvent = "ReferralEvent",
  TwitterEvent = "TwitterEvent",
  DiscordEvent = "DiscordEvent",
}

export interface Conditions {
  url?: string;
  action?: ConditionAction;
  hashtag?: string;
  count?: number;
  brand_id?: number;

  //roblox
  game_id?: string;
  game_name?: string;
  playtime?: number;
  stars?: number;
  badge_id?: string;
  play_history?: string;
  game_pass_id?: string;
  group_id?: string;
  mission_id?: string;
  ugc_id?: string;

  //twitter
  tweet_id?: string;
  user_id?: string;
  //twitter keywords both field in keyword type mission
  keywords?: string;
  tweet_time?: string;

  //discord
  guild?: string;
  level?: number;

  //connect account
  provider?: string;
}

export enum ConditionAction {
  Join = "join",
  Follow = "follow",
  Comment = "comment",
  Like = "like",
}

export enum Providers {
  Google = "google_oauth2",
  Apple = "apple",
  Roblox = "roblox",
  X = "twitter2",
  Discord = "discord",
}
