import { Currency } from "user/models/currency";
import { OnboardingReward } from "./initial-rewards";
import { RewardAdditionalAttributes } from "admin/models/rewards";

export function isUGC(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.UGC;
}

export function isLootBox(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Lootbox;
}

export function isStars(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Stars;
}

export function isStarsLootbox(item: Reward | OnboardingReward) {
  return (
    item.class_name === RewardClassName.Lootbox && item.rarity === Rarity.stars
  );
}

export function isBadge(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Badge;
}

export function isGems(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Gems;
}

export function isRobux(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Robux;
}

export function isSpins(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.STW;
}

export function isMerch(item: Reward | OnboardingReward) {
  return item.class_name === RewardClassName.Merch;
}

export type ReceiveInitialReward = Pick<
  Reward,
  "class_name" | "is_mystery_box_reward" | "rarity" | "is_jackpot_reward"
> & { sellingPrice?: Currency };

export interface Reward {
  id: number;
  // link to game
  game?: string;
  name: string;
  rarity: Rarity;
  identifier: string;
  description: string;
  icon: string;
  class_name?: RewardClassName;
  quantity?: number;
  reward?: Currency;
  price?: Currency;
  amount_allocated: number;
  creator?: string;
  is_mystery_box_reward: boolean;
  is_jackpot_reward: boolean;
  mission_reward_qty: number;
  marketplace_quantity: number;
  original_price?: Currency;
  marketplace_weight?: number;

  allocated: number;
  allocation: number;
  infinity_allocation: boolean;
  is_sold_out: boolean;
  box_animation?: string; // image for receive modal
  notification_amount?: number; // QTY from the notification
  additional_attributes: RewardAdditionalAttributes;
  availability?: string; // added for market daysleft
  jackpot_reward?: Reward;
}

export enum Rarity {
  default = "default",
  stars = "stars",
  special = "special",
  rare = "rare",
  epic = "epic",
  Mystical = "mystical",
  legendary = "legendary",
  gems = "gems",
  spins = "spins",
  merch = "merch",
  jackPot = "jackPot",
  badge = "badge",
}

export enum RewardClassName {
  Stars = "Reward::Stars",
  Gems = "Reward::Gems",
  Lootbox = "Reward::Lootbox",
  UGC = "Reward::Ugc",
  Badge = "Reward::Badge",
  Robux = "Reward::Robux",
  RobuxCard = "Reward::RobuxCard",
  STW = "Reward::Stw",
  Merch = "Reward::Merch",
  // Others = "Reward::Others",
}

export function rewardClassToRarity(type: RewardClassName): Rarity {
  switch (type) {
    case RewardClassName.Stars:
      return Rarity.stars;
    case RewardClassName.Gems:
      return Rarity.gems;
    case RewardClassName.STW:
      return Rarity.spins;
    case RewardClassName.Merch:
      return Rarity.merch;
    case RewardClassName.Lootbox:
      return Rarity.stars;

    default:
      return Rarity.default;
  }
}

export enum RewardKind {
  Stars = "Stars",
  Gems = "Gems",
  Lootbox = "Lootbox",
  UGC = "Ugc",
  Badge = "Badge",
  STW = "STW",
  Others = "Others",
}

export function rewardKindFromClassName(name: RewardClassName): RewardKind {
  switch (name) {
    case RewardClassName.Stars:
      return RewardKind.Stars;
    case RewardClassName.Gems:
      return RewardKind.Gems;
    case RewardClassName.Lootbox:
      return RewardKind.Lootbox;
    case RewardClassName.UGC:
      return RewardKind.UGC;
    case RewardClassName.Badge:
      return RewardKind.Badge;
    default:
      return RewardKind.Others;
  }
}
export interface InitialFlag {
  kind: RewardKind;
  rarity?: Rarity;
  identifier?: string;
  name?: 100;
}
