import { MissionClassName } from "admin/models/mission";
import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import {
  ConditionAction,
  Conditions,
  Mission,
  Providers,
} from "user/models/mission";
import { useMissionStore } from "user/stores/mission";

function openNewWindow(url: string) {
  window.open(url, "_blank");
}

export const usePlayMission = () => {
  async function playMission(mission: Mission) {
    const conditions: Conditions = mission.conditions as Conditions;

    switch (mission.class_name) {
      case MissionClassName.LinkoutEvent:
        return await playLinkoutMission(conditions);

      case MissionClassName.RobloxUpdate:
        await playRobloxMission(conditions);
        return;

      case MissionClassName.TwitterEvent:
        await playTwitterMission(conditions);
        return;

      case MissionClassName.DiscordEvent:
        await playDiscordMission(conditions);
        return;

      case MissionClassName.ConnectAccount:
        await playConnectAccountMission(conditions);
        return;
    }

    if (useMissionStore().isStandardMission(mission)) {
      return await playStandardMission(mission.id);
    }
  }

  async function playStandardMission(id: number) {
    if (id) {
      const res = await container.get(MissionApi).claimStandardMission(id);

      return res;
    }
  }

  async function playLinkoutMission(conditions: Conditions) {
    if (conditions && conditions.url) {
      openNewWindow(conditions.url);

      const res = await container
        .get(MissionApi)
        .triggerLinkout(conditions.url, false);

      return res;
    }
  }

  async function playRobloxMission(conditions: Conditions) {
    openNewWindow(
      "https://www.roblox.com/games/17216786206/PLAYGROUND-AFK-for-UGC",
    );
  }

  async function playTwitterMission(conditions: Conditions) {
    if (
      conditions &&
      ConditionAction.Comment !== conditions.action &&
      conditions.tweet_id
    ) {
      openNewWindow(conditions.tweet_id);
    }

    if (conditions && ConditionAction.Comment === conditions.action) {
      openNewWindow("https://x.com/");
    }
  }

  async function playDiscordMission(conditions: Conditions) {
    if (conditions && conditions.guild) {
      openNewWindow(conditions.guild);
    }
  }

  async function playConnectAccountMission(conditions: Conditions) {
    if (conditions && conditions.provider) {
      let url = window.location.origin;

      switch (conditions.provider) {
        case Providers.Google:
          url = url + "/auth/google_oauth2";
          break;
        case Providers.Discord:
          url = url + "/auth/discord";
          break;
        case Providers.Roblox:
          url = url + "/auth/roblox";
          break;
        case Providers.Apple:
          url = url + "/auth/apple";
          break;
        case Providers.X:
          url = url + "/auth/twitter";
          break;
      }

      openNewWindow(url);
    }
  }

  return { playMission, playStandardMission };
};
