<script setup lang="ts">
import { usePlayMission } from "user/composables/usePlayMission";
import { MISSION_CAMPAIGN_QUERY_PARAM } from "user/routes";
import { useNotificationStore } from "user/stores/notifications";
import { useSessionStore } from "user/stores/session";

const router = useRouter();
const route = useRoute();

const { playStandardMission } = usePlayMission();
const sessionStore = useSessionStore();

const selectedMission = computed<void>(async () => {
  if (sessionStore.isAnonymous) {
    removeCampaignParam();
    return;
  }

  if (!route.query[MISSION_CAMPAIGN_QUERY_PARAM]) {
    return undefined;
  }

  const missionId = route.query[
    MISSION_CAMPAIGN_QUERY_PARAM
  ] as unknown as number;

  try {
    const res = await playStandardMission(missionId);

    removeCampaignParam();

    if (res && res.success) {
      setTimeout(
        async () => await useNotificationStore().fetchNotifications(),
        200,
      );
    }
  } catch (e) {
    console.error(e);
  }

  return missionId;
});

function removeCampaignParam() {
  const query = { ...route.query };

  delete query[MISSION_CAMPAIGN_QUERY_PARAM];

  router.replace({ query });
}
</script>

<template>
  <div class="hidden">
    {{ selectedMission }}
  </div>
</template>
