import type { AdminReward } from "admin/models/rewards";
import { Brand } from "admin/models/brand";
import { Repeatable, RepeatEveryType, RepeatType } from "./repeatable";

export const enum MissionFilter {
  All = "all",
  Completed = "completed",
  Active = "active",
}

export type SubmitStatus = {
  isError: boolean;
  error?: string;
  isSuccess: boolean;
};

export enum MissionCoverType {
  Discord = "/discord.png",
  Roblox = "/roblox.png",
  Twitter = "/x.png",
  YouTube = "/youtube.png",
  General = "/general.png",
}
export type MissionKeyAction = "Follow" | "RT" | "Like" | "Subscribe" | "Post";
export interface AdminMissionCondition {
  url?: string;
  action?: string;
  keywords?: string;
  tweet_time?: string;
  tweet_id?: string;
  count?: number;
  provider?: string;
  type?: string;
  level?: number;
  guild?: string;
  user_id?: string;
  playtime?: number;
  stars?: number;
  mission?: number;
  game_id?: string;
  badge_id?: string;
  game_name?: string;
  game_pass_id?: string;
  group_id?: string;
  mission_id?: string;
  play_history?: string;
  ugc_id?: string;
  day?: number;
}

export interface AdminMission {
  // shared
  id?: number;
  weight: number;
  availability?: string; //start time and end time
  description: string;
  short_description?: string;
  name: string;
  identifier?: string;
  starts_at: string;
  ends_at: string;
  icon?: string;
  icon_url?: string;
  mission_banner?: string;
  mission_banner_url?: string;
  repeatable: boolean;
  enabled?: boolean;
  cooldown?: number;
  conditions: AdminMissionCondition;
  autoaccept?: boolean;
  website?: string;
  game?: string;
  noTimeLimit: boolean;
  repeat: Repeatable;
  isAnyBrands: boolean;
  is_galxe_mission: boolean;
  is_repeatable: boolean;
  referral_type?: string;
  // write
  created_at?: string;
  updated_at?: string;
  class_name?: MissionClassName;
  booth_ids?: number[];
  brand_ids: number[];
  mission_rewards_attributes?: MissionRewardAttributes[];
  // read
  created_by_id?: string;
  updated_by_id?: string;
  type?: MissionClassName;
  rewards: AdminReward[];
  booths?: MissionBooth[];
  brands: Brand[];
  mission_type?: MissionType;
  keyTracker?: string;
  keyUrl?: string;
  claim_codes?: string[];

  // repeatable
  repeat_interval_type?: RepeatEveryType;
  repeat_interval_value: number;
  repeat_day_of_week?: number[];
  repeat_end_time?: string;
  repeatable_start_time?: string;
  isEnds?: boolean;

  users_completed_count?: number;
}

interface MissionBooth {
  booth_id: number;
  indetifier: string;
  name: string;
  enabled: boolean;
  rest: string;
}

export enum MissionClassName {
  LinkoutEvent = "Mission::LinkoutEvent",
  ConnectAccount = "Mission::ConnectAccount",
  InventoryEvent = "Mission::InventoryEvent",
  RobloxUpdate = "Mission::RobloxUpdate",
  ReferralEvent = "Mission::ReferralEvent",
  TwitterEvent = "Mission::TwitterEvent",
  DiscordEvent = "Mission::DiscordEvent",
  ClaimCode = "Mission::ClaimCode",
  DailyLogin = "Mission::DailyLogin",
  CompletionEvent = "Mission::CompletionEvent",
  Standard = "Mission::Standard",
  MissionEvent = "Mission::MissionEvent",
}

export enum StandardMissionType {
  Default = "default",
  Campaign = "campaign",
}

export enum MissionEventType {
  HatchlingSignup = "hatchling_signup",
  HatchlingPoints = "hatchling_points",
}

export enum CompletionMissionType {
  STW = "stw_completion",
  Lootbox = "lootbox_completion",
  Mission = "mission_completion",
}

export enum MissionReferralType {
  Playside = "playside",
  Playground = "playground",
}

export const NotSupportedMissions = [
  MissionClassName.ConnectAccount,
  MissionClassName.InventoryEvent,
  MissionClassName.DiscordEvent,
  // MissionClassName.STW
];

export enum MissionType {
  Linkout = "linkout",
  ConnectAccount = "connect_account",
  CollectPoints = "collect_points",
  Playtime = "playtime",
  Badge = "badge",
  FavGame = "fav_game",
  DailyLogin = "daily_login",

  //Need api
  Mission = "mission",
  MissionQty = "missionQty",
  FavoriteUgc = "favoriteUgc",

  UgcInventory = "ugc_inventory",
  GamePass = "game_pass",
  ReferralEvent = "referral_event",
  TwitterEvent = "twitter_event",
  DiscordEvent = "discord_event",
  ClaimCode = "claim_code",

  //Twitter
  // Post = "post",
  // Like = "like",
  Follow = "twitter_folower",
  Retweet = "twitter_retweet",
  Keyword = "twitter_keyword",
  // Comment = "comment",

  // Discord
  JoinGroup = "join_group",
  LevelUp = "level_up",

  // Inventory
  ItemPurchased = "item_purchased",
  ItemRedeemed = "item_redeemed",
  StarCount = "stars_count",
  GemCount = "gems_count",

  // ConnectAccount
  Google = "google_oauth2",
  Apple = "apple",
  Roblox = "roblox",
  X = "twitter",
  Discord = "discord",

  Campaign = StandardMissionType.Campaign,

  STW = CompletionMissionType.STW,
  LootboxCompletion = CompletionMissionType.Lootbox,
  MissionCompletion = CompletionMissionType.Mission,

  // MissionEvent
  HatchlingSignup = MissionEventType.HatchlingSignup,
  HatchlingPoints = MissionEventType.HatchlingPoints,
}

export enum MissionKind {
  Linkout = "LinkoutEvent",
  ConnectAccount = "ConnectAccount",
  InventoryEvent = "InventoryEvent",
  RobloxUpdate = "RobloxUpdate",
  ReferralEvent = "ReferralEvent",
  TwitterEvent = "TwitterEvent",
  DiscordEvent = "DiscordEvent",
  Standard = "StandardMission",
  Completion = "CompletionEvent",
  MissionEvent = "MissionEvent",
}

export interface MissionRewardAttributes {
  isNew: boolean;
  reward_id?: number;
  allocation?: number;
  id?: number;
}

export function NewMissionInstance(): AdminMission {
  const m: AdminMission = {
    id: undefined,
    weight: 0,
    enabled: true,
    name: "",
    icon: undefined,
    icon_url: undefined,
    mission_banner: undefined,
    mission_banner_url: undefined,
    is_galxe_mission: false,
    description: "",
    short_description: "",
    identifier: "",
    starts_at: "",
    ends_at: "",
    type: undefined,
    brand_ids: [],
    brands: [],
    conditions: {
      url: "",
      action: undefined,
      hashtag: "",
      tweet_id: undefined,
    },
    cooldown: undefined,
    created_at: undefined,
    created_by_id: undefined,
    game: undefined,
    mission_rewards_attributes: [],
    rewards: [],
    updated_at: undefined,
    updated_by_id: undefined,
    website: undefined,
    noTimeLimit: false,
    claim_codes: [""],
    mission_type: undefined,
    referral_type: undefined,
    repeat: {
      type: RepeatType.Every,
      repeat_interval_type: RepeatEveryType.WEEKS,
      repeat_interval_value: 1,
    },
    isAnyBrands: false,
    is_repeatable: false,
  };

  return m;
}
