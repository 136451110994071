<script setup lang="ts">
import { Conditions, Mission, MissionFilter } from "user/models/mission";
import { ButtonSize } from "user/components/FlatButton.vue";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { useNotificationStore } from "user/stores/notifications";
import { useBrandStore } from "user/stores/brand";
import { usePlayMission } from "user/composables/usePlayMission";
import { MissionClassName, Providers } from "user/models/mission";
import { useMissionStore } from "user/stores/mission";
import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import CloseIcon from "admin/assets/x.svg";
import { MISSION_FILTER_QUERY_PARAM, MISSION_QUERY_PARAM } from "user/routes";
import { useHandleMission } from "user/composables/useHandleMission";
import { useSessionStore } from "user/stores/session";

const router = useRouter();
const route = useRoute();

const isRoblox = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.RobloxUpdate;
};

const isClaimCode = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.ClaimCode;
};

const isDailyLogin = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.DailyLogin;
};

const isTwitter = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.TwitterEvent;
};

const isCompletionMission = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.CompletionEvent;
};

const isMissionEventMission = (mission: Mission | undefined) => {
  return mission?.class_name === MissionClassName.MissionEvent;
};

const startQuestBtnRef = ref<{
  childRef: { buttonRef: HTMLButtonElement | null };
} | null>(null);
const signupReminder = ref(false);

const missionStore = useMissionStore();
const sessionStore = useSessionStore();

const { playMission } = usePlayMission();
const { startMission, completeMission } = useHandleMission();
const twitterSignupReminder = ref(false);

const selectedMission = computed<Mission | undefined>(() => {
  if (!route.query[MISSION_QUERY_PARAM]) {
    return undefined;
  }

  let foundedMission: Mission | undefined = missionStore.getMissionById(
    Number(route.query[MISSION_QUERY_PARAM]),
    route.query[MISSION_FILTER_QUERY_PARAM] as MissionFilter,
  );

  if (!foundedMission) {
    foundedMission = missionStore.getMissionByIdentifier(
      route.query[MISSION_QUERY_PARAM] as string,
      route.query[MISSION_FILTER_QUERY_PARAM] as MissionFilter,
    );
  }

  if (!foundedMission) {
    return undefined;
  }

  return foundedMission;
});

const brand = useBrandStore().brands?.find((brand) =>
  brand.missions.find((mis) => mis.id === selectedMission.value?.id),
);

const claimCode = ref("");
const isInProgress = ref(false);
const isError = ref(false);
const isSuccess = ref(false);

async function click() {
  if (sessionStore.isAnonymous) {
    signupReminder.value = true;
    isInProgress.value = false;
    return;
  }

  if (!selectedMission.value) {
    return;
  }

  isInProgress.value = true;

  try {
    let res;
    if (isRoblox(selectedMission.value) || isClaimCode(selectedMission.value)) {
      if (!sessionStore.isAuthorizedIn(Providers.Roblox)) {
        signupReminder.value = true;
        isInProgress.value = false;
        return;
      }
    }

    if (isTwitter(selectedMission.value)) {
      if (!sessionStore.isAuthorizedIn(Providers.X)) {
        twitterSignupReminder.value = true;
        isInProgress.value = false;
        return;
      }
    }

    if (isRoblox(selectedMission.value)) {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      }
      if (selectedMission.value.isActive) {
        res = await completeMission(selectedMission.value);
      }
    } else if (isDailyLogin(selectedMission.value)) {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      }
      if (selectedMission.value.isClaimable) {
        res = await completeMission(selectedMission.value);
      }
    } else if (isTwitter(selectedMission.value)) {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      } else {
        res = await completeMission(selectedMission.value);
      }
    } else if (isCompletionMission(selectedMission.value)) {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      } else {
        res = await completeMission(selectedMission.value);
      }
    } else if (isMissionEventMission(selectedMission.value)) {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      } else {
        res = await completeMission(selectedMission.value);
      }
    } else {
      if (selectedMission.value.isAvailable) {
        res = await startMission(selectedMission.value);
      }
      res = await playMission(selectedMission.value);
    }

    if (res && res.success) {
      handleClose();
      setTimeout(
        async () => await useNotificationStore().fetchNotifications(),
        200,
      );
      await missionStore.fetchMissions();
    }
    isInProgress.value = false;
  } catch (error) {
    isInProgress.value = false;
    console.log("click ~ error:", error);
    requestAnimationFrame(animateErrorHandleMission);
  }
}

function animateErrorHandleMission() {
  if (!startQuestBtnRef.value || !startQuestBtnRef.value?.childRef?.buttonRef) {
    return;
  }

  startQuestBtnRef.value.childRef.buttonRef.classList.add(
    "animate-error-shake-fixed-offset",
  );

  setTimeout(() => {
    if (
      !startQuestBtnRef.value ||
      !startQuestBtnRef.value?.childRef?.buttonRef
    ) {
      return;
    }
    startQuestBtnRef.value.childRef.buttonRef.classList.remove(
      "animate-error-shake-fixed-offset",
    );
  }, 2000);
}

async function claimUgc() {
  if (!selectedMission.value) {
    return;
  }
  if (!claimCode.value) {
    return;
  }

  if (isClaimCode(selectedMission.value)) {
    if (!sessionStore.isAuthorizedIn(Providers.Roblox)) {
      signupReminder.value = true;
      return;
    }
  }

  try {
    if (selectedMission.value.isAvailable) {
      await startMission(selectedMission.value);
    }

    const res = await container
      .get(MissionApi)
      .claimUgc(selectedMission.value?.id, claimCode.value);

    if (res.success) {
      isSuccess.value = true;
      setTimeout(async () => {
        await completeMission(selectedMission.value);
        await missionStore.fetchMissions();
        setTimeout(
          async () => await useNotificationStore().fetchNotifications(),
          200,
        );
        handleClose();
      }, 1000);
    } else {
      isError.value = true;
    }
  } catch (error) {
    isError.value = true;
    console.log("claimUgc ~ error:", error);
    requestAnimationFrame(animateErrorHandleMission);
  }
}

const daysLeft = computed(() => {
  if (!selectedMission.value) {
    return;
  }
  // Calculate dates til close from mission
  const left = "";
  let start = 0;

  try {
    if (
      selectedMission.value.availability &&
      !selectedMission.value.next_repeat_time &&
      !selectedMission.value.mission_expiry
    ) {
      const starts_at =
        selectedMission.value.availability?.split("..")[0] ?? "";
      if (!starts_at) {
        return "";
      }
      const expires_at =
        selectedMission.value.availability?.split("..")[1] ?? "";
      start = Date.parse(expires_at.replaceAll("-", "/"));
    }

    if (
      !selectedMission.value.availability &&
      selectedMission.value.next_repeat_time
    ) {
      start = Date.parse(selectedMission.value.next_repeat_time);
    }

    if (
      selectedMission.value.mission_expiry &&
      !selectedMission.value.next_repeat_time
    ) {
      start = Date.parse(selectedMission.value.mission_expiry);
    }

    const durationDays = differenceInDays(start, new Date());

    if (durationDays != 0) {
      return `Ends in ${durationDays} days`;
    }

    const durationHours = differenceInHours(start, new Date());

    if (durationHours != 0) {
      return `Ends in ${durationHours} hours`;
    }

    const durationMin = differenceInMinutes(start, new Date());

    if (durationMin != 0) {
      return `Ends in ${durationMin} minutes`;
    }

    return `Ends in 1 minutes`;
  } catch (err) {
    return left;
  }
});

const isDisplayPlayButton = computed(() => {
  if (!selectedMission.value) {
    return false;
  }

  if (
    !selectedMission.value.conditions &&
    !missionStore.isStandardMission(selectedMission.value)
  ) {
    return false;
  }

  if (
    selectedMission.value.class_name === MissionClassName.DailyLogin &&
    selectedMission.value.isActive &&
    !selectedMission.value.isClaimable
  ) {
    return false;
  }

  switch (true) {
    case isDailyLogin(selectedMission.value):
    case isRoblox(selectedMission.value):
    case selectedMission.value.class_name === MissionClassName.LinkoutEvent &&
      (selectedMission.value.conditions as Conditions).url !== undefined:
    case isTwitter(selectedMission.value) &&
      (selectedMission.value.conditions as Conditions).tweet_id !== undefined:
    case isTwitter(selectedMission.value) &&
      (selectedMission.value.conditions as Conditions).user_id !== undefined:
    case selectedMission.value.class_name === MissionClassName.DiscordEvent &&
      (selectedMission.value.conditions as Conditions).guild !== undefined:
    case selectedMission.value.class_name === MissionClassName.ConnectAccount &&
      (selectedMission.value.conditions as Conditions).provider !== undefined:
    case isTwitter(selectedMission.value) &&
      (selectedMission.value.conditions as Conditions).tweet_time !==
        undefined &&
      (selectedMission.value.conditions as Conditions).keywords !== undefined:
      return true;
    case isClaimCode(selectedMission.value):
      return true;
    case missionStore.isStandardMission(selectedMission.value):
      return true;
    case selectedMission.value.class_name === MissionClassName.CompletionEvent:
      return true;
    case selectedMission.value.class_name === MissionClassName.MissionEvent:
      return true;

    default:
      return false;
  }
});

const buttonText = computed(() => {
  if (
    selectedMission.value &&
    (isRoblox(selectedMission.value) ||
      isDailyLogin(selectedMission.value) ||
      isTwitter(selectedMission.value))
  ) {
    if (isInProgress.value) {
      return "Verifying Eligibility...";
    }

    if (selectedMission.value.isActive) {
      return "Activate";
    }

    if (selectedMission.value.isClaimable) {
      return "Claim";
    }
  }
  return "Start Quest";
});

const buttonKind = computed(() => {
  if (selectedMission.value && isRoblox(selectedMission.value)) {
    if (selectedMission.value.isAvailable) {
      return "play";
    }

    return "claim";
  }
  return "play";
});

function handleClose() {
  nextTick(() => {
    claimCode.value = "";
    isInProgress.value = false;
    signupReminder.value = false;
    twitterSignupReminder.value = false;
    isError.value = false;
    isSuccess.value = false;
    router.replace({
      query: {
        [MISSION_QUERY_PARAM]: undefined,
        [MISSION_FILTER_QUERY_PARAM]: undefined,
      },
    });
  });
}
</script>

<template>
  <Modal
    :open="Boolean(selectedMission)"
    @close="handleClose"
    class="min-h-[calc(100vh-100rem)] md:min-h-[calc(100vh-160rem)] md:w-[620rem]"
  >
    <div class="flex flex-col h-full w-full">
      <div class="flex flex-col w-full">
        <div
          class="w-full flex px-4 py-2 rounded-t-ml relative"
          :class="{
            'bg-aquaToPurple': !selectedMission?.isCompleted,
            'bg-grayToBlack': selectedMission?.isCompleted,
          }"
        >
          <h3 class="text-[20rem]/[26rem] min-h-[26rem] font-semibold">
            {{ brand?.name || "Quest" }}
          </h3>

          <div
            class="absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer p-2 bg-[#B2B2B280] rounded-full"
            @click="handleClose"
          >
            <img :src="CloseIcon" alt="Icon" />
          </div>
        </div>

        <div class="flex w-full justify-center">
          <img
            v-if="selectedMission"
            :src="
              selectedMission.mission_banner
                ? selectedMission.mission_banner
                : selectedMission.icon
            "
            alt="Banner"
            class="object-cover"
          />
        </div>
      </div>

      <div class="overflow-auto w-full grow">
        <div
          class="overflow-hidden w-full flex justify-end mb-2"
          v-if="daysLeft"
        >
          <MissionBanner
            :text="daysLeft"
            class="!text-[13rem]/[15rem] !py-[2rem] md:!py-1 md:!text-[16rem]/[20rem]"
          />
        </div>
        <div
          class="flex w-full gap-4"
          :class="{
            'mt-4': !daysLeft,
          }"
        >
          <div
            class="px-6 text-[16rem]/[20rem] md:text-[20rem]/[26rem] font-semibold"
          >
            {{ selectedMission?.name }}
          </div>
        </div>

        <div
          class="text-[13rem]/[17rem] md:text-[15rem]/[21rem] w-full px-6 [&>a]:text-blueOblivion [&>a]:underline mt-4"
          v-html="selectedMission?.description"
        />

        <div
          class="flex gap-10 w-full justify-center mt-4 px-6"
          v-if="selectedMission?.class_name === MissionClassName.ClaimCode"
        >
          <template v-if="!selectedMission.isCompleted">
            <div class="flex flex-col gap-4 w-full">
              <CheckCodeInput
                v-model="claimCode"
                :is-in-progress="isInProgress"
                :is-error="isError"
                :is-success="isSuccess"
                @update:model-value="isError = false"
              />
            </div>
          </template>
          <template v-else>
            <div class="text-lg text-black flex w-full">
              UGC has been added to your Backpack 🎁
            </div>
          </template>
        </div>

        <div class="text-base font-bold w-full px-6 mt-4">Rewards:</div>
        <div
          class="flex gap-x-3 p-6 overflow-auto no-scrollbar max-w-[325rem] md:max-w-[600rem]"
        >
          <RewardCard
            v-for="(reward, index) in selectedMission?.rewards"
            :key="index"
            :reward="reward"
            :brand-name="brand?.name"
            :size="'sm'"
          />
        </div>
      </div>

      <div class="p-3 md:p-6 w-full flex">
        <ButtonWithSound
          ref="startQuestBtnRef"
          v-if="!selectedMission?.isCompleted"
          :size="ButtonSize.lg"
          :disabled="isInProgress || !isDisplayPlayButton"
          :kind="buttonKind"
          borderless
          @click="
            !isInProgress &&
            selectedMission?.class_name === MissionClassName.ClaimCode
              ? claimUgc()
              : click()
          "
          class="w-full hover:scale-105 transition-all"
        >
          {{ buttonText }}
        </ButtonWithSound>
        <ButtonWithSound
          v-if="selectedMission?.isCompleted"
          :size="ButtonSize.md"
          borderless
          @click="router.push({ name: 'backpack-ugc' })"
          class="w-full"
        >
          Go to Backpack
        </ButtonWithSound>
      </div>
      <SignupReminderDialog
        v-model:open="signupReminder"
        @confirm="
          () => {
            isInProgress = false;
            signupReminder = false;
            sessionStore.isAnonymous
              ? router.push({ name: 'login' })
              : router.push({ name: 'profile' });
          }
        "
      />
      <TwitterSignupModal
        v-model:open="twitterSignupReminder"
        @confirm="
          () => {
            isInProgress = false;
            twitterSignupReminder = false;
            sessionStore.isAnonymous
              ? router.push({ name: 'login' })
              : router.push({ name: 'profile' });
          }
        "
      />
    </div>
  </Modal>
</template>
