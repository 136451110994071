<script setup lang="ts">
import { ButtonSize } from "user/components/FlatButton.vue";
import { Currency } from "user/models/currency";
import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import {
  isBadge,
  isMerch,
  isSpins,
  isStars,
  isUGC,
  Rarity,
  Reward,
  RewardClassName,
} from "user/models/items";

const props = defineProps<{
  prize: Reward;
  prizeAnimationActive: boolean;
  done: boolean;
  isMarket?: boolean;
  isMultiple?: boolean;
  isOnboard?: boolean;
  rewardScreenFile?: any;
  classToExcludeFromScreenshot?: string;
  sellingPrice?: Currency;
  copySuccess?: boolean;
  availableSpinVouchers?: number;
}>();
const isPostedOnX = ref(false);

const emit = defineEmits<{
  goToHome: [];
  goToBackpack: [];
  goToMarket: [];
  goToSpinAndWin: [];
  setDone: [];
  continue: [];
  copyImage: [];
  spinAgain: [];
  buySpin: [];
  spin: [];
}>();

function handleMainButton() {
  if (props.availableSpinVouchers && props.availableSpinVouchers > 0) {
    return emit("spinAgain");
  }

  if (
    props.availableSpinVouchers !== undefined &&
    props.availableSpinVouchers === 0
  ) {
    return emit("buySpin");
  }

  if (props.isOnboard) {
    return emit("spin");
  }

  if (isStars(props.prize) || isMerch(props.prize)) {
    emit("goToMarket");
    return;
  }

  if (isSpins(props.prize)) {
    emit("goToSpinAndWin");
    return;
  }

  if (isBadge(props.prize) || isUGC(props.prize)) {
    emit("goToBackpack");
    return;
  }
  emit("goToMarket");
}

function handleSubButton() {
  if (
    isStars(props.prize) ||
    isSpins(props.prize) ||
    isMerch(props.prize) ||
    isBadge(props.prize)
  ) {
    emit("goToHome");
    return;
  }
  emit("goToHome");
}

const mainButtonText = computed(() => {
  if (props.availableSpinVouchers && props.availableSpinVouchers > 0) {
    return "Spin Again";
  }

  if (props.isOnboard) {
    return "Spin & Win";
  }

  if (
    props.availableSpinVouchers !== undefined &&
    props.availableSpinVouchers === 0
  ) {
    return "Buy Spins";
  }

  if (isStars(props.prize) || isMerch(props.prize)) {
    return "Go to Market";
  }

  if (isSpins(props.prize)) {
    return "Spin & Win";
  }

  if (isBadge(props.prize) || isUGC(props.prize)) {
    return "Go to Backpack";
  }

  return "Go to Market";
});

const subButtonText = computed(() => {
  if (
    isStars(props.prize) ||
    isSpins(props.prize) ||
    isMerch(props.prize) ||
    isBadge(props.prize) ||
    isUGC(props.prize)
  ) {
    return "Back to Home";
  }

  return "Back to Home";
});

const qty = computed(() => {
  if (props.prize.marketplace_quantity) {
    return props.prize.marketplace_quantity;
  }
  if (props.prize.notification_amount) {
    return props.prize.notification_amount;
  }

  return 0;
});

async function handlePostOnX() {
  try {
    if (isPostedOnX.value) {
      return;
    }
    await container.get(MissionApi).postOnTwitter(props.prize.id);
    isPostedOnX.value = true;
  } catch (error) {
    console.error("Post on X failed!", error);
  }
}

const titleText = computed(() => {
  if (props.isMarket) {
    ("You have successfully redeemed");
  }

  if (props.prize.class_name === RewardClassName.STW) {
    return "You’ve got";
  }

  return "You’ve won";
});

const rewardText = computed(() => {
  let delimiter = " x ";

  if (props.prize.class_name === RewardClassName.Stars) {
    delimiter = " ";
  }
  if (props.isOnboard) {
    return `<span class="font-bold">${props.prize.name}</span>!`;
  }

  if (props.isMarket) {
    return `a <span class="font-bold">${props.prize.name}</span>!`;
  }
  if (props.prize.amount_allocated && props.prize.amount_allocated > 1) {
    return `<span class="font-bold">${props.prize.amount_allocated}${delimiter}${props.prize.name}</span>!`;
  }
  if (qty.value > 1) {
    return `<span class="font-bold">${qty.value}${delimiter}${props.prize.name}</span>!`;
  }

  return `a <span class="font-bold">${props.prize.name}</span>!`;
});

const callToActionText = computed(() => {
  if (props.prize.class_name === RewardClassName.Badge)
    return `View ${qty.value > 1 ? "them" : "it"} in your Backpack`;

  if (props.prize.class_name === RewardClassName.STW)
    return "Spin now for rewards or visit the Market for more cool items!";

  return "Redeem prizes or use them in the Market";
});
</script>

<template>
  <div class="min-h-[200rem]">
    <ReceiveModalHeader
      :hide-amount-on-mobile="isSpins(prize)"
      :amount_allocated="
        prize.amount_allocated
          ? prize.amount_allocated
          : prize.notification_amount
            ? prize.notification_amount
            : 0
      "
      :class="{
        hidden: !done,
        'text-black': prize.rarity === Rarity.stars,
        'text-white': prize.rarity !== Rarity.stars,
      }"
    >
      HOORAY!
    </ReceiveModalHeader>

    <div
      :class="{
        hidden: !done,
      }"
      class="text-center text-black text-[20rem]/[28rem] font-medium"
    >
      <div>
        <div>
          {{ titleText }}
          <!-- <span v-if="isMarket">
            <span class="font-bold">a {{ prize.name }} </span>!
          </span>
          <span v-else-if="prize.amount_allocated && prize.amount_allocated > 1"
            ><span class="font-bold">
              {{ `${prize.amount_allocated} x ${prize.name}` }} </span
            >!</span
          >
          <span v-else-if="qty > 1">
            <span class="font-bold"> {{ `${qty} x ${prize.name}` }} </span>!
          </span>
          <span v-else>
            <span class="font-bold">a {{ prize.name }} </span>!
          </span> -->
          <span v-html="rewardText"></span>
        </div>
        <div v-if="!isOnboard">
          {{ callToActionText }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="flex justify-center items-center grow w-full min-h-[calc(100%-390rem)] mb-2"
  >
    <PrizeAnimation
      :asset="prize.box_animation ? prize.box_animation : prize.icon"
      :open="prizeAnimationActive"
      @done="emit('setDone')"
    />
  </div>

  <div
    :class="`${classToExcludeFromScreenshot} flex flex-col justify-center items-center gap-4 min-h-[190rem]`"
  >
    <div
      class="flex flex-col gap-2"
      :class="{
        hidden: !done,
      }"
    >
      <ButtonWithSound
        v-if="isMultiple"
        @click="emit('continue')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home hover:scale-105 transition-all"
      >
        Continue
      </ButtonWithSound>
      <ButtonWithSound
        v-if="!isMultiple"
        @click="handleMainButton"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home hover:scale-105 transition-all"
      >
        {{ mainButtonText }}
      </ButtonWithSound>

      <template v-if="isSpins(prize)">
        <ButtonWithSound
          v-if="!isMultiple"
          @click="emit('buySpin')"
          :size="ButtonSize.lg"
          kind="hollow"
          class="z-[60] shadow-home hover:scale-105 transition-all"
        >
          Go to Market
        </ButtonWithSound>
      </template>

      <ButtonWithSound
        id="back"
        v-if="!isMultiple && !isUGC(prize) && !isSpins(prize)"
        @click="handleSubButton"
        :size="ButtonSize.lg"
        class="z-[60] hidden md:flex shadow-home hover:scale-105 transition-all"
        kind="hollow"
      >
        {{ subButtonText }}
      </ButtonWithSound>
      <template v-if="isUGC(prize)">
        <!-- <ShareRewardNative
          v-if="isNativeShareSupported"
          :copy-success="copySuccess"
          @share-click="handleNativeShare"
        >
          <ButtonWithSound
            id="back"
            v-if="!isMultiple"
            @click="handleSubButton"
            :size="ButtonSize.lg"
            class="z-[60] hidden md:flex shadow-home hover:scale-105 transition-all"
            kind="hollow"
          >
            {{ subButtonText }}
          </ButtonWithSound>
        </ShareRewardNative> -->
        <ShareRewardToTwitter
          @copy-image="emit('copyImage')"
          @share="handlePostOnX"
        />
      </template>
    </div>
  </div>
</template>
