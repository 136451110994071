import { Rarity, RewardClassName } from "user/models/items";

export default function getBackgroundColor(
  rarity: Rarity,
  palette: "radial" | "linear" = "linear",
  class_name?: RewardClassName,
  is_mystery_box_reward: boolean = false,
  is_jackpot_reward: boolean = false,
) {
  if (is_mystery_box_reward) {
    return "bg-mystery-radial";
  }

  if (palette === "linear") {
    if (rarity === Rarity.spins) {
      return "bg-spin-linear";
    }
    if (rarity === Rarity.gems) {
      return "bg-gems-linear";
    }
    if (rarity === Rarity.default) {
      return "bg-default-linear";
    }
    if (rarity === Rarity.Mystical) {
      return "bg-mystical-linear";
    }
    if (
      rarity === Rarity.legendary ||
      is_jackpot_reward ||
      rarity === Rarity.jackPot
    ) {
      return "bg-legendary-linear";
    }
    if (rarity === Rarity.rare) {
      return "bg-rare-linear";
    }
    if (rarity === Rarity.special) {
      return "bg-special-linear";
    }
    if (rarity === Rarity.stars) {
      return "bg-stars-linear";
    }
    if (rarity === Rarity.epic) {
      return "bg-epic-linear";
    }
    if (rarity === Rarity.merch) {
      return "bg-merch-linear";
    }
    if (rarity === Rarity.badge) {
      return "bg-badge-linear";
    }
  }

  if (palette === "radial") {
    if (rarity === Rarity.spins) {
      return "bg-spin-radial";
    }
    if (rarity === Rarity.gems) {
      return "bg-gems-radial";
    }
    if (rarity === Rarity.default) {
      return "bg-default-radial";
    }
    if (rarity === Rarity.Mystical) {
      return "bg-mystical-radial";
    }
    if (
      rarity === Rarity.legendary ||
      is_jackpot_reward ||
      rarity === Rarity.jackPot
    ) {
      return "bg-legendary-radial";
    }
    if (rarity === Rarity.rare) {
      return "bg-rare-radial";
    }
    if (rarity === Rarity.special) {
      return "bg-special-radial";
    }
    if (class_name === RewardClassName.Lootbox && rarity === Rarity.stars) {
      return "bg-stars-lootbox-radial";
    }
    if (class_name !== RewardClassName.Lootbox && rarity === Rarity.stars) {
      return "bg-stars-radial";
    }
    if (rarity === Rarity.epic) {
      return "bg-epic-radial";
    }
    if (rarity === Rarity.merch) {
      return "bg-merch-radial";
    }
    if (rarity === Rarity.badge) {
      return "bg-badge-radial";
    }
  }

  return "bg-rewardCard-default";
}
